:root {
    --activeColor: #ffbe47;
}

.App {
    text-align: center;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

a {
    color: var(--activeColor);
    text-decoration: none;
}

.container {
    width: 100vw;
    height: 100vh;
}

.inputText {
    width: calc(100% - 50px);
    height: calc(50% - 50px);
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 2.5rem;
    padding: 25px;
    resize: none;
}

.translateButton {
    position: absolute;
    top: calc(50vh - 100px);
    color: white;
    background-color: #47474785;
    padding: 25px;
    border-radius: 15px;
    left: 50%;
    transform: translate(-50%, 0);
}

.loadingContainer {
    background-color: #2196f3;
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: calc(50% - 50px);
    font-size: 2.5em;
    padding: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.jap-output {
    margin: 15px 0;
}

.romanji-output {
    font-size: 0.65em;
    margin: 0;
}

.active {
    color: var(--activeColor);
}

.footer {
    font-size: 1rem;
}

.moshimo {
    cursor: pointer;
    font-weight: 500;
    font-size: 1.1rem;
    color: var(--activeColor);
    letter-spacing: 2px;
}

.footer>p {
    margin: 2.5px 0;
}